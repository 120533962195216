import { MISSIONS_TYPES } from './types';
import { POST_MISSION_REQUEST_OBJ, PUT_MISSION_REQUEST_OBJ, GET_AGRESSO_CODES_REQUEST_OBJ, GET_VISUAL_TIME_GROUPS_REQUEST_OBJ, GET_ALL_MISSIONS_REQUEST_OBJ } from './utils/functions';
import { SUCESS_MESSAGE_CREATE_MISSION, SUCESS_MESSAGE_EDIT_MISSION, ERROR_MESSAGE_CREATE_MISSION, ERROR_MESSAGE_EDIT_MISSION, ERROR_MESSAGE_SOME_GET_IN_MISSION, ERROR_MESSAGE_GET_ALL_MISSION, MISSION_URL } from './utils/consts';
import { inserSectionInSectionViews, insertSectionInModuleStructure, editSectionInSectionsViews, editSectionInModuleStructure } from 'components/Managment/Users/store/actions';
import { STRUCTURE_CATEGORY_TYPES_NAMES } from 'components/Managment/Users/store/utils/consts';
import axios from 'axios'
import { showMessage } from 'app/store/fuse/messageSlice'
import { getUserOrganizationChart } from 'components/Managment/Users/store/actions';
import store from 'app/store';
import { peopleMovementsLogs, sectionsActionsLogs } from 'app/js/generalRequests';
import { getRequestAuthHeader } from 'app/js/generalFunctions';
import { useDispatchError } from 'app/js/customHooks/GeneralHooks';

export const setCreateModal = (createModalState) => {
  return (dispatch) => {
    dispatch({
      type: MISSIONS_TYPES.SET_CREATE_MODAL,
      payload: createModalState
    })
  }
}

export const setEditModal = (editModalState) => {
  return (dispatch) => {
    dispatch({
      type: MISSIONS_TYPES.SET_EDIT_MODAL,
      payload: editModalState
    })
  }
}

export const setMissionsSelected = (rowSelected) => {

  return (dispatch) => {
    dispatch({
      type: MISSIONS_TYPES.SET_MISSION_SELECTED,
      payload: rowSelected
    })
  }
}

export const postMissionAction = (mission) => {
  return async (dispatch) => {
    try {
      const postMissionResponse = await axios(POST_MISSION_REQUEST_OBJ(mission))

      dispatch(showMessage(SUCESS_MESSAGE_CREATE_MISSION))
      const missionPosted = postMissionResponse.data
      const misionInfo = { id: missionPosted.id, ...mission }
      const { staff, id: missionId } = misionInfo


      // Ç Post mission
      dispatch({ type: MISSIONS_TYPES.POST_MISSION, payload: misionInfo })

      // Ç Logs
      sectionsActionsLogs({ moduleName: "Mission", action: "Create", moduleId: missionId })
      peopleMovementsLogs({ moduleId: missionId, moduleTableName: "mision_relacionada", staff })

      const { wpsIds } = store.getState().fuse.userComponente


      const missionWp = misionInfo.id_workPackage

      if (wpsIds.includes(missionWp)) {
        dispatch(inserSectionInSectionViews({ sectionInfo: misionInfo, sectionCategoryType: STRUCTURE_CATEGORY_TYPES_NAMES.mission }))
        dispatch(insertSectionInModuleStructure({ sectionInfo: misionInfo, sectionCategoryType: STRUCTURE_CATEGORY_TYPES_NAMES.mission }))
      }
      else dispatch(getUserOrganizationChart())


      return missionPosted
    }
    catch (e) {
      useDispatchError( dispatch, e, ERROR_MESSAGE_CREATE_MISSION)
      return Promise.reject(false)
    }
  }
}

export const putMissionAction = (idMission, missionEdited) => {
  return async (dispatch) => {
    try {
      const putMissionResponse = await axios(PUT_MISSION_REQUEST_OBJ(idMission, missionEdited))
      if (putMissionResponse.status !== 200) throw new Error()
      dispatch(showMessage(SUCESS_MESSAGE_EDIT_MISSION))
      const missionPushed = await Promise.resolve(putMissionResponse.data);
      const misionInfo = { id: idMission, ...missionEdited }
      const { staff, prevStaff, id: missionId } = misionInfo
      // Ç Edit mission
      dispatch({ type: MISSIONS_TYPES.PUT_MISSION, payload: misionInfo })

      // Ç Logs
      sectionsActionsLogs({ moduleName: "Mission", action: "Edit", moduleId: missionId })
      peopleMovementsLogs({ moduleId: missionId, moduleTableName: "mision_relacionada", staff, prevStaff })

      const { departamentalAndNoDepartamentalMissionsIds, person } = store.getState().fuse.userComponente
      if (departamentalAndNoDepartamentalMissionsIds.includes(misionInfo.id)) {
        dispatch(editSectionInSectionsViews({ sectionInfo: misionInfo, sectionCategoryType: STRUCTURE_CATEGORY_TYPES_NAMES.mission }))
        dispatch(editSectionInModuleStructure({ sectionInfo: misionInfo, sectionCategoryType: STRUCTURE_CATEGORY_TYPES_NAMES.mission }))
      }
      else {
        dispatch(getUserOrganizationChart())
      }
      return missionPushed
    }
    catch (e) {
      console.log("🚀 ~ return ~ e:", e)
      useDispatchError( dispatch, e, ERROR_MESSAGE_EDIT_MISSION)
      return Promise.reject(false)
      // dispatch(showMessage(ERROR_MESSAGE_EDIT_MISSION))
      // return Promise.reject(false)
    }
  }
}

export const getVisualTimeGroups = () => {
  return async (dispatch) => {

    try {
      const vtgRequest = await axios(GET_VISUAL_TIME_GROUPS_REQUEST_OBJ())
      dispatch({
        type: MISSIONS_TYPES.GET_VISUAL_TIME_GROUPS,
        payload: vtgRequest.data
      })
    }
    catch {
      dispatch(showMessage(ERROR_MESSAGE_SOME_GET_IN_MISSION))
    }

  }
}

export const getAgressoCodes = () => {
  return async (dispatch) => {

    try {
      const agressoCodeRequest = await axios(GET_AGRESSO_CODES_REQUEST_OBJ())
      dispatch({
        type: MISSIONS_TYPES.GET_AGRESSO_CODES,
        payload: agressoCodeRequest.data
      })
    }
    catch {
      dispatch(showMessage(ERROR_MESSAGE_SOME_GET_IN_MISSION))
    }

  }
}

let cancelToken;
export const getAllMissionsAction = () => {
  return async dispatch => {
    if (cancelToken) {
      cancelToken.cancel('Request canceled due to a new request.');
    }
    cancelToken = axios.CancelToken.source();
    try {
      dispatch({ type: MISSIONS_TYPES.LOADING_MISSIONS })
      const allMissionsRequest = await axios(({
        method: "GET",
        url: MISSION_URL,
        headers: getRequestAuthHeader(),
        cancelToken: cancelToken.token,
      }))
      if (!allMissionsRequest) return
      dispatch({ type: MISSIONS_TYPES.GET_ALL_MISSIONS, payload: allMissionsRequest.data })
    }
    catch (error) {
      if (axios.isCancel(error)) {
        console.log('Request canceled:', error.message);
      } else {
        console.log(error.message);
        dispatch({ type: MISSIONS_TYPES.ERROR_MISSIONS })
        dispatch(showMessage(ERROR_MESSAGE_GET_ALL_MISSION))
      }
    }
  }
}

// Fetch all missions with associated KPIs
export const fetchMissionsWithKpis = () => {
  return async dispatch => {
    if (cancelToken) {
      cancelToken.cancel('Request canceled due to a new request.')
    }
    cancelToken = axios.CancelToken.source()
    try {
      dispatch({ type: MISSIONS_TYPES.LOADING_MISSIONS })
      const response = await axios({
        method: "GET",
        url: `${MISSION_URL}misionWithKPIs/`,
        headers: getRequestAuthHeader(),
        cancelToken: cancelToken.token,
      })
      if (!response) return
      dispatch({ type: MISSIONS_TYPES.GET_ALL_MISSIONS, payload: response.data })
    }
    catch (error) {
      if (axios.isCancel(error)) {
        console.log('Request canceled:', error.message)
      } else {
        console.log(error.message)
        dispatch({ type: MISSIONS_TYPES.ERROR_MISSIONS })
        dispatch(showMessage(ERROR_MESSAGE_GET_ALL_MISSION))
      }
    }
  }
}
