import { useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import { DialogContent, Grid, DialogActions, Button, Autocomplete, TextField, Typography } from '@mui/material';
import { useStyles } from 'app/js/customHooks/GeneralHooks';
import { useCloseCreateInspectorModal, useEditInspector, useGetPossibleInspectorBassicInfo, useGetPrograms, useSaveInspector } from '../utils/hooks';
import { useDispatch, useSelector } from 'react-redux';
import { DateYearFilterYMD } from 'components/GlobalComponents/ModalsFilters/DateFilterYMD';
import { UserCantBeInspector } from './ModalsComponents/UserCantBeInspector';
import { UserCanBeInspector } from './ModalsComponents/UserCanBeInspector';
import dayjs from 'dayjs';
import { resetUserCanBeInspector } from '../store/AuthOfInspectorsSlice';

const MODAL_INITIAL_VALUES = {
    inspectorSelected: null,
    programSelected: null,
    startDate: null,
    finalDate: null,
}

export const CreateInspectorModal = () => {

    const classes = useStyles();
    const dispatch = useDispatch()

    // ! userCanBeInspector QUITAR CUANDO !USERSELECTED

    // * Store
    const { createInspectorModalVisibility, userCanBeInspector, belowPeopleInStructure, inspectorBasicListRowSelected, editInspectorModalVisibility } = useSelector(({ fuse }) => fuse.inspectorsComponent)

    // * States
    const [modalValues, setModalValues] = useState(()=>{
        if(!editInspectorModalVisibility) return MODAL_INITIAL_VALUES
        const {inspector_info, program_info, initial_date} = inspectorBasicListRowSelected
        return {
            inspectorSelected: inspector_info, programSelected: program_info, startDate: dayjs(initial_date),
            prevValues: { inspectorSelected: inspector_info, programSelected: program_info, startDate: dayjs(initial_date) },
        }
    })
    const { inspectorSelected, programSelected, startDate } = modalValues

    useEffect(()=> !inspectorSelected && dispatch(resetUserCanBeInspector()),[inspectorSelected])
    // * Functions
    const setInspectorSelected = (value) => setModalValues(state => ({ ...state, inspectorSelected: value }))
    const setProgramSelected = (value) => setModalValues(state => ({ ...state, programSelected: value }))
    const setStartDate = (value) => setModalValues(state => ({ ...state, startDate: value }))
    const { programsList } = useGetPrograms()
    const { closeModal } = useCloseCreateInspectorModal()
    const { saveInspector } = useSaveInspector({ modalValues })
    const {editInspector}  = useEditInspector({ modalValues })

    // * Hooks
    useGetPossibleInspectorBassicInfo(inspectorSelected)




    return (
        <Dialog open={createInspectorModalVisibility || editInspectorModalVisibility} onClose={closeModal} fullWidth maxWidth="md">
            <DialogTitle classes={{ root: classes.customDialogTitle }}  >
                Create inspector authorization process
            </DialogTitle>

            <DialogContent>

                <Grid container columns={11} spacing={1} style={{ marginTop: '1px' }}>


                    <Grid item xs={5}>

                        <Autocomplete
                            id="tags-outlined"
                            options={belowPeopleInStructure}
                            value={inspectorSelected}
                            getOptionLabel={(option) => `${option?.IDRes} - ${option?.first_name}  ${option?.last_name}`}
                            isOptionEqualToValue={(option, value) => option?.IDRes === value?.IDRes}
                            renderOption={(props, option) => <li {...props} key={option?.id}>{`${option?.IDRes} - ${option?.first_name}  ${option?.last_name}`}</li>}
                            onChange={(event, value) => setInspectorSelected(value)}
                            filterSelectedOptions
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Inspector"
                                    placeholder="Inspector"
                                    size="small"
                                    fullWidth={true}
                                />
                            )}
                        />

                    </Grid>

                    <Grid item xs={3}>

                        <Autocomplete
                            id="tags-outlined"
                            options={programsList}
                            value={programSelected}
                            getOptionLabel={(option) => option?.name}
                            isOptionEqualToValue={(option, value) => option?.name === value?.name}
                            onChange={(event, value) => setProgramSelected(value)}
                            filterSelectedOptions
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Program"
                                    placeholder="Program"
                                    size="small"
                                    fullWidth={true}
                                />
                            )}
                        />
                    </Grid>

                    <Grid item xs={3}>
                        <DateYearFilterYMD componentContextDate={startDate} setFatherDate={setStartDate} label='Initial date' />
                    </Grid>


                    <Grid item xs={12}>
                        <Typography variant='p'>
                            The tutor, acting on behalf of TRIGO ADR IBERIA, authorises the inspector to carry out inspection of AIRBUS D&S's products.
                        </Typography>

                    </Grid>

                </Grid>


                {userCanBeInspector === false && inspectorSelected && <UserCantBeInspector inspectorSelected={inspectorSelected} />}

                {!!userCanBeInspector && inspectorSelected && <UserCanBeInspector />}

            </DialogContent>

            <DialogActions>
                <Button onClick={closeModal}> Close </Button>
                {userCanBeInspector && <Button onClick={createInspectorModalVisibility ? saveInspector : editInspector}> Save </Button>}
            </DialogActions>



        </Dialog>


    )
}
