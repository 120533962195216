import {

    SET_LOADING_USERS_TRUE,
    SET_USER_LIST,
    SET_USERS_FETCH_ERROR,

    SESION_USER,
    SESION_USER_EXITO,
    SESION_USER_ERROR,

    PERMISOS_SESION_USER,
    PERMISOS_SESION_USER_EXITO,
    PERMISOS_SESION_USER_ERROR,

    CAMBIAR_VALOR_SELECCION_GRID_USER,
    CAMBIAR_VALOR_SELECCION_GRID_USER_EXITO,
    CAMBIAR_VALOR_SELECCION_GRID_USER_ERROR,

    OBTENER_CONTRATOS_USER,
    OBTENER_CONTRATOS_USER_EXITO,
    OBTENER_CONTRATOS_USER_EXITO_RESPALDO,
    OBTENER_CONTRATOS_USER_ERROR,

    MOSTRAR_USER_PERMISOS,
    MOSTRAR_USER_PERMISOS_EXITO,
    MOSTRAR_USER_PERMISOS_ERROR,

    GET_USER_AGRESSO,
    GET_USER_AGRESSO_EXITO,
    GET_USER_AGRESSO_ERROR,

    PUT_USER,
    PUT_USER_EXITO,
    PUT_USER_ERROR,

    INSERTAR_NEW_USER,
    INSERTAR_NEW_USER_EXITO,
    INSERTAR_NEW_USER_ERROR,

    MOSTRAR_USER_PERMISOS_BY_GROUP,
    MOSTRAR_USER_PERMISOS_BY_GROUP_EXITO,
    MOSTRAR_USER_PERMISOS_BY_GROUP_ERROR,

    MOSTRAR_ALL_USER,
    MOSTRAR_ALL_USER_EXITO,
    MOSTRAR_ALL_USER_ERROR,

    PUT_PASSWORD_USER,
    PUT_PASSWORD_USER_EXITO,
    PUT_PASSWORD_USER_ERROR,

    PUT_PASSWORD_USER_MANUAL,
    PUT_PASSWORD_USER_MANUAL_EXITO,
    PUT_PASSWORD_USER_MANUAL_ERROR,

    GET_BLOQUES_HEREDADOS,
    GET_BLOQUES_HEREDADOS_EXITO,
    GET_BLOQUES_HEREDADOS_ERROR,

    GET_ID_USER_LOGIN,
    GET_ID_USER_LOGIN_EXITO,
    GET_ID_USER_LOGIN_ERROR,

    SET_SECTIONS_VIEWS,
    // !! 2.0
    SET_USER_SECTIONS_VIEWS,
    INSERT_NEW_SECTION_IN_SECTIONS_VIEWS,
    EDIT_SECTION_IN_SECTIONS_VIEWS,

    SET_MODULES_STRUCTURE,
    SET_USER_EMPTY,
    DELETE_PERMISSIONS,
    RESET_USER_VALUES
} from './types';


// cada reducer tiene su propio state
const initialState = {
    listBloquesHeredadosUser: {},
    usersListAPI: [],
    allUsersListAPI: [],
    contractUserListAPI: [],
    contractUserListAPIRespaldo: [],
    userSelected: null,
    error: null,
    fReuqestThrow: false,
    loading: true,
    person: {},
    personPermisos: [],
    visibilidadModalSinPermisos: false,
    permisosName: [],
    permisosByGroup: [],
    userAgresso: {},
    nuevoUsuarioCreado: {},
    userSectionsViewsIds: {},

    sectionsViewsIsLoad: false,

    divisionsIds: [],
    subdivisionsIds: [],
    departmentDiectoratesIds: [],
    departmentsIds: [],
    programsIds: [],
    servicesIds: [],
    departamentalWpsIds: [],
    wpsIds: [],
    missionsIds: [],
    departamentalMissionsIds: [],
    submissionsIds: [],
    departamentalSubmissionsIds: [],

    divisionsInfo: [],
    subdivisionsInfo: [],
    departmentDiectoratesInfo: [],
    departmentsInfo: [],
    programsInfo: [],
    servicesInfo: [],
    departamentalWpsInfo: [],
    wpsInfo: [],
    missionsInfo: [],
    departamentalMissionsInfo: [],
    submissionsInfo: [],
    departamentalSubmissionsInfo: [],

    departamentalAndNoDepartamentalWpsInfo: [],
    departamentalAndNoDepartamentalMissionsInfo: [],
    departamentalAndNoDepartamentalSubMissionsInfo: [],
    departamentalAndNoDepartamentalWpsIds: [],
    departamentalAndNoDepartamentalMissionsIds: [],
    departamentalAndNoDepartamentalSubMissionsIds: [],

    organizationChart: []
}


export default function (state = initialState, action) {

    switch (action.type) {

        //**********GET BLOQUES HEREDADOS Y NO USUARIO**********************************/
        case SET_SECTIONS_VIEWS:
            return {
                ...state,
                userSectionsViewsIds: action.payload
            }

        case SET_MODULES_STRUCTURE:
            // console.log("🚀 STRUCTURE", action.payload)
            return {
                ...state,
                organizationChart: action.payload
            }

        case SET_USER_SECTIONS_VIEWS:

            // console.log("🚀 SET_USER_SECTIONS_VIEWS", action.payload)
            const  { divisions, subdivisions, departmentDiectorates, departments, services, departamentalWps, wps, departamentalMissions, missions, submissions, departamentalSubmisions, departamentalAndNoDepartamentalWps, departamentalAndNoDepartamentalMissions, departamentalAndNoDepartamentalSubMissions } = action.payload

            return {
                ...state,
                sectionsViewsIsLoad: true,
                divisionsIds: divisions.ids,

                subdivisionsIds: subdivisions.ids,
                departmentDiectoratesIds: departmentDiectorates.ids,
                // programsIds: programs.ids,
                servicesIds: services.ids,
                departmentsIds: departments.ids,
                departamentalWpsIds: departamentalWps.ids,
                wpsIds: wps.ids,
                missionsIds: missions.ids,
                departamentalMissionsIds: departamentalMissions.ids,
                submissionsIds: submissions.ids,
                departamentalSubmissionsIds: departamentalSubmisions.ids,

                divisionsInfo: divisions.info,
                subdivisionsInfo: subdivisions.info,
                departmentDiectoratesInfo: departmentDiectorates.info,
                // programsInfo: programs.info,
                servicesInfo: services.info,
                departmentsInfo: departments.info,
                departamentalWpsInfo: departamentalWps.info,
                wpsInfo: wps.info,
                missionsInfo: missions.info,
                departamentalMissionsInfo: departamentalMissions.info,
                submissionsInfo: submissions.info,
                departamentalSubmissionsInfo: departamentalSubmisions.info,

                departamentalAndNoDepartamentalWpsIds: departamentalAndNoDepartamentalWps.ids,
                departamentalAndNoDepartamentalMissionsIds: departamentalAndNoDepartamentalMissions.ids,
                departamentalAndNoDepartamentalSubMissionsIds: departamentalAndNoDepartamentalSubMissions.ids,

                departamentalAndNoDepartamentalWpsInfo:  departamentalAndNoDepartamentalWps.info,
                departamentalAndNoDepartamentalMissionsInfo: departamentalAndNoDepartamentalMissions.info,
                departamentalAndNoDepartamentalSubMissionsInfo: departamentalAndNoDepartamentalSubMissions.info,
            }

        case INSERT_NEW_SECTION_IN_SECTIONS_VIEWS:

            const {sectionTypesInStore: newSectionNamesInStore, sectionInfo: newSectionInfo} = action.payload
            const {idsSectionName: idNewName, infoSectionName:infoNewName} = newSectionNamesInStore

            const newValuesPost = {}


            for(let i in infoNewName){
                console.log("🚀 ~ infoEditName[i]:", infoNewName[i], state[infoNewName[i]])
                newValuesPost[infoNewName[i]] = [newSectionInfo, ...state[infoNewName[i]]]
                newValuesPost[idNewName[i]] = [newSectionInfo.id, ...state[idNewName[i]]]
            }


            return{
                ...state,
                // [idNew]: [...state[idNew], newSectionInfo.id],
                // [infoNewName]: [...state[infoNewName], newSectionInfo]
                ...newValuesPost
            }

        case EDIT_SECTION_IN_SECTIONS_VIEWS:


            const {sectionTypesInStore: editedSectionNamesInStore, sectionInfo: editedSectionInfo} = action.payload
            const { infoSectionName:infoEditName} = editedSectionNamesInStore

            // Ç Sustitucion por de las listas ids e info por nuevos valores

            const newValues = {}

            for(let i in infoEditName){
                const indexOfSectionInSectionsViews = state[infoEditName[i]].findIndex(section => section.id === editedSectionInfo.id)
                const {indexValue, ...sbEdited} = editedSectionInfo
                const editSubsInfoList = state[infoEditName[i]].with(indexOfSectionInSectionsViews, sbEdited)
                newValues[infoEditName[i]] = editSubsInfoList
            }


            return{
                ...state,
                ...newValues
            }


        case GET_BLOQUES_HEREDADOS:
            return {
                ...state,
                // loading: action.payload
            }

        case GET_BLOQUES_HEREDADOS_EXITO:
            return {
                ...state,
                // loading: false,
                listBloquesHeredadosUser: action.payload

            }

        case GET_BLOQUES_HEREDADOS_ERROR:
            return {
                ...state,
                // loading: false,
                error: action.payload

            }

        //RESET PASSWORD

        case PUT_PASSWORD_USER:
            return {
                ...state,
                // loading: action.payload
            }

        case PUT_PASSWORD_USER_EXITO:
            return {
                ...state,
                // loading: false,
                error: null
            }

        case PUT_PASSWORD_USER_ERROR:
            return {
                ...state,
                // loading: false,
                error: action.payload

            }


        //RESET PASSWORD MANUAL

        case PUT_PASSWORD_USER_MANUAL:
            return {
                ...state,
                // loading: action.payload
            }

        case PUT_PASSWORD_USER_MANUAL_EXITO:
            return {
                ...state,
                // loading: false,
                error: null,
            }

        case PUT_PASSWORD_USER_MANUAL_ERROR:
            return {
                ...state,
                // loading: false,
                error: action.payload

            }

        //********** MOSTRAR ALL USER API**********************************/

        case MOSTRAR_ALL_USER:
            return {
                ...state,
                // loading: action.payload
            }

        case MOSTRAR_ALL_USER_EXITO:
            return {
                ...state,
                // loading: false,
                error: null,
                allUsersListAPI: action.payload
            }

        case MOSTRAR_ALL_USER_ERROR:
            return {
                ...state,
                // loading: false,
                error: action.payload

            }

        //post user

        case INSERTAR_NEW_USER:
            return {
                ...state,
                // loading: action.payload
            }

        case INSERTAR_NEW_USER_EXITO:
            return {
                ...state,
                error: null,
                nuevoUsuarioCreado: action.payload,
                usersListAPI: [action.payload,...state.usersListAPI]
            }

        case INSERTAR_NEW_USER_ERROR:
            return {
                ...state,
                // loading: false,
                error: action.payload

            }

        //UPDATE USER
        case PUT_USER:
            return {
                ...state,
                // loading: action.payload
            }



        case PUT_USER_ERROR:
            return {
                ...state,
                // loading: false,
                error: action.payload

            }
        //**********GET USER AGRESSO**********************************/
        case GET_USER_AGRESSO:
            return {
                ...state,
                // loading: action.payload
            }

        case GET_USER_AGRESSO_EXITO:
            return {
                ...state,
                // loading: false,
                userAgresso: action.payload

            }

        case GET_USER_AGRESSO_ERROR:
            return {
                ...state,
                // loading: false,
                error: action.payload

            }
        //**********USUARIO DE LA SESION ACTUAL **********************************/
        case SESION_USER:
            return {
                ...state,
                // loading: action.payload
            }

        case SESION_USER_EXITO:
            return {
                ...state,
                // loading: false,
                person: action.payload

            }
        case SET_USER_EMPTY:
            return{
                ...state,
                person: {}
            }

        case SESION_USER_ERROR:
            return {
                ...state,
                // loading: false,
                error: action.payload

            }

        //**********PERMISOS USUARIO DE LA SESION ACTUAL **********************************/
        case PERMISOS_SESION_USER:
            return {
                ...state,
                // loading: action.payload
            }

        case PERMISOS_SESION_USER_EXITO:
            return {
                ...state,
                // loading: false,
                personPermisos: action.payload

            }

        case PERMISOS_SESION_USER_ERROR:
            return {
                ...state,
                // loading: false,
                error: action.payload

            }

        //**********PERMISOS PERMISOS DEL USUARIO DE LOS GRUPOS **********************************/

        case MOSTRAR_USER_PERMISOS_BY_GROUP:
            return {
                ...state,
                // loading: action.payload
            }

        case MOSTRAR_USER_PERMISOS_BY_GROUP_EXITO:
            return {
                ...state,
                // loading: false,
                permisosByGroup: action.payload

            }

        case MOSTRAR_USER_PERMISOS_BY_GROUP_ERROR:
            return {
                ...state,
                // loading: false,
                error: action.payload

            }

        //**********PERMISOS USUARIO DE LA SESION ACTUAL **********************************/

        case MOSTRAR_USER_PERMISOS:
            return {
                ...state,
                // loading: action.payload
            }

        case MOSTRAR_USER_PERMISOS_EXITO:
            return {
                ...state,
                // loading: false,
                permisosName: action.payload

            }

        case MOSTRAR_USER_PERMISOS_ERROR:
            return {
                ...state,
                // loading: false,
                error: action.payload

            }

        //********** OBTENER CONTRATOS USER API**********************************/

        case OBTENER_CONTRATOS_USER:
            return {
                ...state,
                // loading: action.payload
            }

        case OBTENER_CONTRATOS_USER_EXITO:
            return {
                ...state,
                // loading: false,
                error: null,
                contractUserListAPI: action.payload
            }

        case OBTENER_CONTRATOS_USER_EXITO_RESPALDO:
            return {
                ...state,
                // loading: false,
                error: null,
                contractUserListAPIRespaldo: action.payload
            }


        case OBTENER_CONTRATOS_USER_ERROR:
            return {
                ...state,
                // loading: false,
                error: action.payload

            }

        //********** MOSTRAR USER API**********************************/

        case SET_LOADING_USERS_TRUE:
            return {
                ...state,
                loading: true,
                fReuqestThrow: true,
            }

        case SET_USER_LIST:
            return {
                ...state,
                loading: false,
                error: null,

                usersListAPI: action.payload
            }

        case SET_USERS_FETCH_ERROR:
            return {
                ...state,
                loading: false,
                error: true
            }
        //**********FILA SELECIONADA GRID **********************************/



        case CAMBIAR_VALOR_SELECCION_GRID_USER_EXITO:
            return {
                ...state,
                userSelected: action.payload

            }

        case DELETE_PERMISSIONS:
            return {
                ...state,
                personPermisos: []

            }
        // case RESET_USER_VALUES:
        //     return initialState



        default:
            return state

    }

}
