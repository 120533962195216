import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import FilterListIcon from '@mui/icons-material/FilterList'
import PropTypes from 'prop-types'
import {
    setFirstRenderAction,
    setvisibilityAction
} from "../store/actions.js"
import { IconButton } from '@mui/material'

export const FilterIcon = () => {
    const dispatch = useDispatch()
    const filterVisibility = useSelector(state => state.fuse.filterComponente.filterVisibility)

    const showHideFilter = useCallback((e) => {
        e.preventDefault()
        e.stopPropagation()
        dispatch(setFirstRenderAction(false))
        dispatch(setvisibilityAction(!filterVisibility))
    }, [dispatch, filterVisibility])

    return (
        <IconButton
            sx={{
                transition: 'all 0.3s',
                border: '1px solid',
                borderColor: 'transparent',
                zIndex: 1000,
                '&:hover': {
                    border: '1px solid',
                    borderColor: '#27272750',
                },
            }}
            className="absolute right-10 top-4"
            onClick={showHideFilter}
        >
            <FilterListIcon className="text-4xl" />
        </IconButton >
    )
}

FilterIcon.propTypes = {
    filterVisibility: PropTypes.bool
}
