import { useContext } from "react";
import { Table } from "components/GlobalComponents/tables/Table2"
import { LinearLoader } from 'components/GlobalComponents/loaders/loader';
import { skillMatrixContext } from "../utils/providerSkillMatrix";
import { CenteredDivInfo } from "components/GlobalComponents/CenteredDiv/TextCenteredDiv";

export default function Requirements({ missionSelected }) {
    const {
        loading,
        requirements,
        missionWithNoVTG,
        errorSkillMatrix,
        noContentSkillMatrix
    } = useContext(skillMatrixContext);

    const renderContent = () => {
        if (!missionSelected) {
            return <CenteredDivInfo infoText={"Select a mission"} />
        }

        if (loading) {
            return <LinearLoader />
        }

        if (requirements.data) {
            return (
                <Table
                    rowsProp={requirements.data}
                    columnsProp={requirements.columns}
                    loading={false}
                    funcionSetValue={() => { }}
                />
            )
        }

        if (missionWithNoVTG && !errorSkillMatrix) {
            return <CenteredDivInfo infoText={"Select a mission with visual time group associated"} />
        }

        if (errorSkillMatrix) {
            return <CenteredDivInfo infoText={"Error, please, try again"} />
        }

        if (noContentSkillMatrix) {
            return <CenteredDivInfo infoText={"There are no requirements for the selected visual time group"} />
        }

        return null
    };

    return <>{renderContent()}</>
}
