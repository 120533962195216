import './styles/tags.css'
import PropTypes from 'prop-types'
import { NOT_AVALIABLE_COURSES } from '../utils/consts';

const getClassName = (tag) => !NOT_AVALIABLE_COURSES.includes(tag) ? 'tag-item' : 'tag-item-red';

export const MoodleTrainingTags = ({ tagsList, skillMatrix = true }) => (
    <div className='tags-container'>
        {tagsList.map((tag, i) => (
            tag ? (
            <span className={skillMatrix ? getClassName(tag) : 'tag-border'} key={`${tag}${i}`}>
                {tag}
                </span>
            ) : "-"
        ))}
    </div>
);

MoodleTrainingTags.propTypes = {
    tagsList: PropTypes.arrayOf(PropTypes.string).isRequired,
    skillMatrix: PropTypes.bool,
}
