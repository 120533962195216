import { Table } from "components/GlobalComponents/tables/Table2"
import {
    INSPECTORS_TABLE_COLUMNS,
    INSPECTOR_BASIC_LIST_COLUMNS,
    JOBS_TABLE_COLUMNS
} from "../utils/consts"
import { useDispatch, useSelector } from "react-redux"
import { LinearLoader } from "components/GlobalComponents/loaders/loader"
import { CenteredDivInfo } from "components/GlobalComponents/CenteredDiv/TextCenteredDiv"
import {
    setInspectorBasicListRowSelectedAction,
    setInspectorRowSelectedAction,
    setJobRowSelected,
    dataPaginationDetails
} from "../store/AuthOfInspectorsSlice"

export const InspectorsTable = () => {
    const dispatch = useDispatch()

    const {
        inspectorsList,
        loadingInspectorsList,
        errorInspectorsList,
        inspectorBasicList,
        detailsAuthTableVisibility,
        inspectorDataDetailTable,
        jobsTableVisibility,
        inspectorJobsInJobsTable
    } = useSelector(({ fuse }) => fuse.inspectorsComponent)

    const paginationDetailsSelector = useSelector(dataPaginationDetails)

    const setInspectorSelected = (value) =>
        dispatch(setInspectorRowSelectedAction(value))
    const setInspectorBasicListSelected = (value) =>
        dispatch(setInspectorBasicListRowSelectedAction(value))
    const setJobSelected = (value) => dispatch(setJobRowSelected(value))

    if (loadingInspectorsList && !errorInspectorsList) {
        return <LinearLoader />
    }

    if (!loadingInspectorsList && errorInspectorsList) {
        return <CenteredDivInfo infoText="Error, please try again" />
    }

    const tableConfigs = [
        {
            condition: !!inspectorsList.length && !detailsAuthTableVisibility && !jobsTableVisibility,
            config: {
                rowsProp: inspectorBasicList,
                columnsProp: INSPECTOR_BASIC_LIST_COLUMNS,
                funcionSetValue: setInspectorBasicListSelected,
                apiCustomPagination: paginationDetailsSelector
            }
        },
        {
            condition: detailsAuthTableVisibility,
            config: {
                rowsProp: inspectorDataDetailTable || inspectorDataDetailTable[0]?.detailedAuthResult || [],
                columnsProp: INSPECTORS_TABLE_COLUMNS,
                funcionSetValue: setInspectorSelected
            }
        },
        {
            condition: jobsTableVisibility,
            config: {
                rowsProp: inspectorJobsInJobsTable,
                columnsProp: JOBS_TABLE_COLUMNS,
                funcionSetValue: setJobSelected
            }
        }
    ]

    const activeTable = tableConfigs.find(({ condition }) => condition)

    return activeTable ? (
        <Table
            {...activeTable.config}
            loading={false}
        />
    ) : null
}
