import React, { useCallback } from 'react'
import Button from '@mui/material/Button';
import { setFilterSearchButtom, setFirstRenderAction, setSummaryRequestDone, setvisibilityAction } from 'components/Filter/store/actions'
import { useDispatch, useSelector } from 'react-redux'
import SearchIcon from '@mui/icons-material/Search'

export const FilterSearchButtom = () => {
    const dispatch = useDispatch()
    const filterVisibility = useSelector(({ fuse }) => fuse.filterComponente.filterVisibility)

    const setSearchTrue = useCallback(() => {
        dispatch(setFilterSearchButtom(true))
        dispatch(setSummaryRequestDone(false))
    }, [dispatch])

    const showHideFilter = useCallback((e) => {
        e.preventDefault()
        e.stopPropagation()
        dispatch(setFirstRenderAction(false))
        dispatch(setvisibilityAction(!filterVisibility))
    }, [dispatch, filterVisibility])

    const handleClick = useCallback((e) => {
        setSearchTrue()
        showHideFilter(e)
    }, [setSearchTrue, showHideFilter]);

    return (
        <div className='px-8 w-full'>
            <Button
                sx={{
                    mt: 1,
                    width: '100%',
                    padding: '10px',
                    borderRadius: '4px',
                    backgroundColor: 'white',
                    color: 'black',
                    border: '1px solid',
                    borderColor: 'white',
                    fontSize: '18px',
                    transition: 'all 0.3s',
                    '&:hover': {
                        backgroundColor: '#ffffff70',
                        color: 'white',
                        borderColor: '#ffffff70',
                        scale: '0.98',
                    },
                }}
                onClick={handleClick}
                variant="contained"
            >
                <SearchIcon />
            </Button>
        </div>
    )
}
