import dayjs from "dayjs"
import { AUTH_STATES_BY_DATE, PREVIOUS_SCOPES_CONDITIONS, SCOPE_NAMES_JOB_MODAL, SCOPES_BASIC_NAMES, SCOPES_WITH_RELATED_OBJ } from "./consts"

export  const tooglePerformanceCorrectly=(MODAL_STATES_KEYS, setModalValuesPropFunc, performanceIsCorrect )=>{
    setModalValuesPropFunc(MODAL_STATES_KEYS.operationPerformedCorrecly, performanceIsCorrect)
    setModalValuesPropFunc(MODAL_STATES_KEYS.operationPerformedInCorrecly, !performanceIsCorrect)
}


export const setScopesRelated = (scopeCode, setJobModalState) => {

    const scopeCodes = SCOPES_WITH_RELATED_OBJ[scopeCode]

    setJobModalState(prev => {
        const { scopesSelected } = prev
        let newScopes = { ...scopesSelected }

        if (!scopesSelected[scopeCode]) {
            for (let scopeCode of scopeCodes) newScopes[scopeCode] = true
        }
        else newScopes[scopeCode] = false
        return ({ ...prev, scopesSelected: newScopes })
    })
}



export const scopesFormGroupControl =(scopeCode, scopesSelected, setJobModalState)=>{
    const disabled_actions = {
        'QIN.002': Object.keys(scopesSelected).some(key => !!scopesSelected[key] && key !== 'QIN.002'),
        'QIN.003': scopesSelected['QIN.004'] || scopesSelected['QIN.005'],
        'QIN.004': scopesSelected['QIN.006'],
        'QIN.005': scopesSelected['QIN.006'],
    }

    const scopes_form_label = SCOPE_NAMES_JOB_MODAL[scopeCode]
    const scopes_form_onChangeFunc =()=> setScopesRelated(scopeCode, setJobModalState)
    const scopes_form_checked = scopesSelected[scopeCode]

    const scopes_form_disabled = disabled_actions[scopeCode] ?? false
    return {scopes_form_label, scopes_form_onChangeFunc, scopes_form_checked , scopes_form_disabled}

}

export const addStateByLastJobDateDateToInpectorList =(inspectorList)=>{
    return inspectorList.map(inspectorAuth => {
        const { jobs, final_date } = inspectorAuth
        const lastJobDate = jobs.at(-1)?.date
        if (!lastJobDate) return { ...inspectorAuth, authState: AUTH_STATES_BY_DATE.pending }
        const monthWithoutJobs = dayjs().diff(dayjs(lastJobDate), 'month')
        if(lastJobDate && !final_date) return  { ...inspectorAuth, authState: AUTH_STATES_BY_DATE.onGoing }
        else if(monthWithoutJobs < 5)  return { ...inspectorAuth, authState: AUTH_STATES_BY_DATE.inForce }
        else if(monthWithoutJobs >= 5 && monthWithoutJobs < 6) return { ...inspectorAuth, authState: AUTH_STATES_BY_DATE.soonExpires }
        else return { ...inspectorAuth, authState: AUTH_STATES_BY_DATE.expired }
    })
}


export const setInspectorsBasicList =(inspectorList) => {
    // console.log("🚀 ~ setInspectorsBasicList ~ inspectorList:", inspectorList)
    return inspectorList.reduce((acc, entry)=>{

        const {id, tutor_info: entryTutor, inspector_info: entryInspector, program_info: entryProgram, final_date: entryFinalDate, initial_date} = entry
        const scope = SCOPES_BASIC_NAMES[entry.scope]
        let entryToAdd = {
            id,
            tutor_info: entryTutor,
            inspector_info: entryInspector,
            program_info: entryProgram,
            initial_date,
            scopes: [scope],
            inspector_name: `${entryInspector.first_name} ${entryInspector.last_name}`,
            program_name: entryProgram.name,
        }
        entryToAdd = entryFinalDate ? entryToAdd : {...entryToAdd, scopes: []}

        if(!acc.at(-1)) return [entryToAdd]
        const lastAuthAdded = acc.at(-1)
        const { inspector_info: accInspector, program_info: accProgram, scopes: accScope } = lastAuthAdded
        const isSameInspector = entryInspector.id === accInspector.id && entryProgram.id === accProgram.id
        const inpectorUpdated = entryFinalDate ?  {...lastAuthAdded, scopes: [...accScope, scope]} : lastAuthAdded

        if (isSameInspector) acc.splice(-1, 1, inpectorUpdated)
        else acc = [...acc, entryToAdd]
        return acc
    }, [])

}

export const setInspectorsDetailList = (inspectorsList, inspectorBasicListRowSelected) => {
    const {inspector_info: basicRowInspector, program_info: basicRowProgram} = inspectorBasicListRowSelected
    return inspectorsList.filter(({inspector_info, program_info})=> inspector_info.id === basicRowInspector.id && program_info.id === basicRowProgram.id)
}

export const checkPreviousScopes =(scopeSelected, inspectorAuthAllRowsByScope) => {

    if(!PREVIOUS_SCOPES_CONDITIONS[scopeSelected]) return [true]
    const scopesMustBeChecked = PREVIOUS_SCOPES_CONDITIONS[scopeSelected]
    const previousScopesCheck = scopesMustBeChecked.every(scopeCode => inspectorAuthAllRowsByScope[scopeCode])
    if(!previousScopesCheck) return [false,  `To close this authorization process, user must complete ${scopesMustBeChecked.join(', ')}`]
    return [true]
}


export const getAllInspectorJobs = (inspectorDataDetailTable) => {
    let jobsObj = {}
    for (let inspector of inspectorDataDetailTable) {
        const {jobs} = inspector
        for(let job of jobs){
            const jobScopes = Object.entries(job.scopes).map(([key, value]) => value && key).filter(Boolean).map(scope =>SCOPE_NAMES_JOB_MODAL[scope] )
            if(!jobsObj[job.id]) jobsObj[job.id] = {...job, scopesTable: jobScopes}
        }
    }
    return Object.values(jobsObj).sort((a,b)=> a.date.localeCompare(b.date))
}


export const NO_ENOUGHT_CORRECT_JOBS_MESSAGE=(inspector_info, program_info, correctPerformancesJobNumber)=> ({ message: `To close the inspector authorization, the inspector must have at least three jobs with correct performance, and ${inspector_info.first_name} has ${correctPerformancesJobNumber} for program '${program_info.name}'` , variant: 'error' })

export const NO_ENOUGHT_TIME_OPEN_JOB_MESSAGE=(inspector_info, program_info, authMonthsOpen)=> ({ message: `To close the inspector authorization, the authorization must have been open for at least 3 months, and ${inspector_info.first_name}'s authorization for program '${program_info.name}' has been open for ${authMonthsOpen} months` , variant: 'error' })

export const INSPECTOR_DOESNT_HAVE_SCOPE_COURSE = (inspector_info, scopeSelected) => ({ message: ` This authorization can't be closed because ${inspector_info.first_name} doesn't have the required courses for scope '${SCOPE_NAMES_JOB_MODAL[scopeSelected]}'  ` , variant: 'error' })
export const INSPECTOR_AUTH_ALREDY_CLOSED = (inspector_info, program_info) => ({ message: `${inspector_info.first_name}'s authorization for program '${program_info.name}' is aledry closed` , variant: 'info' })
export const INPECTOR_NO_JOBS_MESSAGE=(inspector_info, program_info) =>  ({ message: `${inspector_info.first_name} does't have jobs assigned for program ${program_info.name}` , variant: 'error' })


export const insertScopeNameInInspectorList = inspectorList => inspectorList.map(insAuth => ({...insAuth, tableScope: SCOPE_NAMES_JOB_MODAL[insAuth.scope]}))
