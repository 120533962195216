import { useDispatch, useSelector } from "react-redux"
import { useEffect, useMemo, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import { DialogContent, Grid, DialogActions, Button, Autocomplete, TextField, FormGroup, Typography, FormControlLabel, Checkbox } from '@mui/material';
import { useStyles } from 'app/js/customHooks/GeneralHooks';
import { useCloseContinuousEvModal, useCloseJobModal, usePostInspectorJob } from '../utils/hooks';
import { DateYearFilterYMD } from 'components/GlobalComponents/ModalsFilters/DateFilterYMD';
import { DisabledFieldRenderName, DisabledUserFilter } from "components/GlobalComponents/ModalsFilters/DisabledFields";
import { ModalTexField } from "components/GlobalComponents/ModalsFilters/TextFields";
import { JOB_MODAL_STATES_KEYS, CONTINUOUS_EV_MODAL_STATIC_INITIAL_STATE, SCOPES_WITH_RELATED_OBJ } from "../utils/consts";
import { scopesFormGroupControl, tooglePerformanceCorrectly } from "../utils/functions";
import { UsersAutoComplete } from "components/GlobalComponents/ModalsFilters/AutocompleteFields";



export const ContinuousEvJobModal = () => {
    const classes = useStyles();

    const { inspectorDataDetailTable, inspectorBasicListRowSelected: { inspector_info: inspectorRowSelected, program_info: programRowSelected, tutor_info: tutorRowSelected }, continuousEvaluationModalVisibility, usersStructureUpperN0, jobModalOnlyViewVisibility, jobRowSelected } = useSelector(({ fuse }) => fuse.inspectorsComponent)



    const { closeModal } = useCloseContinuousEvModal()

    const [jobModalState, setJobModalState] = useState(() => {
        if (!jobRowSelected) {
            return {
                inspectorSelected: inspectorRowSelected,
                tutorSelected: tutorRowSelected,
                programSelected: programRowSelected,
                ...CONTINUOUS_EV_MODAL_STATIC_INITIAL_STATE
            }
        }
        else {
            const {
                tutor_info, inspector_info, program_info, scopes, profile,
                order, operation, evaluated_activities, peration_performed_correctly, date, deviations, comments, active
            } = jobRowSelected
            return {
                inspectorSelected: inspector_info,
                tutorSelected: tutor_info,
                programSelected: program_info,
                profile,
                order,
                operation,
                evaluatedActivities: evaluated_activities,
                operationPerformedCorrecly: peration_performed_correctly,
                operationPerformedInCorrecly: !peration_performed_correctly,
                jobDateSelected: date,
                deviations,
                comments,
                scopesSelected: scopes
            }
        }
    })



    const { inspectorSelected, tutorSelected, programSelected, profile, order, operation, evaluatedActivities, deviations, comments, scopesSelected, operationPerformedCorrecly, operationPerformedInCorrecly, jobDateSelected } = jobModalState

    const setModalValuesPropFunc = (key, value) => setJobModalState(prev => ({ ...prev, [key]: value }))

    const { postInspectorJob } = usePostInspectorJob()
    const authsEndScopes = jobRowSelected ?
        Object.keys(jobRowSelected.scopes).filter(scope => !!jobRowSelected.scopes[scope]) :
        inspectorDataDetailTable.filter(auth => !!auth.final_date).map(({ scope }) => scope)




    return (
        <Dialog open={continuousEvaluationModalVisibility || jobModalOnlyViewVisibility} onClose={closeModal} fullWidth maxWidth="md">

            <DialogTitle classes={{ root: classes.customDialogTitle }}  >
                {jobRowSelected ? 'Job informattion' : 'Create job for continuous evaluation'}

            </DialogTitle>

            <DialogContent>

                <Grid container spacing={4} style={{ marginTop: '1px' }}>
                    <Grid item xs={12} sm={6}>
                        <Grid container spacing={2}>

                            <Grid item xs={12}>
                                <DisabledUserFilter optionAndValue={inspectorSelected} label={'Inspector'} />

                            </Grid>

                            <Grid item xs={12}>
                                <DisabledFieldRenderName optionAndValue={programSelected} label={'Program'} />

                            </Grid>

                            <Grid item xs={12}>
                                <UsersAutoComplete options={usersStructureUpperN0} value={tutorSelected} setValueFunc={(value) => setModalValuesPropFunc(JOB_MODAL_STATES_KEYS.tutorSelected, value)} label={'Supervised by'} disabled={jobModalOnlyViewVisibility} />
                            </Grid>



                            <Grid item xs={12}>
                                <ModalTexField label={'Profile'} value={profile} onChangeFunc={(value) => setModalValuesPropFunc(JOB_MODAL_STATES_KEYS.profile, value)} disabled={jobModalOnlyViewVisibility} />
                            </Grid>

                            <Grid item xs={12}>
                                <ModalTexField label={'Order'} value={order} onChangeFunc={(value) => setModalValuesPropFunc(JOB_MODAL_STATES_KEYS.order, value)} disabled={jobModalOnlyViewVisibility} />
                            </Grid>

                            <Grid item xs={12}>
                                <ModalTexField label={'Operation'} value={operation} onChangeFunc={(value) => setModalValuesPropFunc(JOB_MODAL_STATES_KEYS.operation, value)} disabled={jobModalOnlyViewVisibility} />
                            </Grid>

                            <Grid item xs={12}>
                                <ModalTexField label={'Related activities'} value={evaluatedActivities} rows={3} onChangeFunc={(value) => setModalValuesPropFunc(JOB_MODAL_STATES_KEYS.evaluatedActivities, value)} disabled={jobModalOnlyViewVisibility} />
                            </Grid>


                        </Grid>

                    </Grid>



                    <Grid item xs={12} sm={6}>
                        <Grid container spacing={2}>


                            <Grid item xs={12}>
                                <Typography variant="p">Operation performed correctly</Typography>
                                <FormGroup className="flex-row">
                                    <FormControlLabel control={<Checkbox onChange={() => tooglePerformanceCorrectly(JOB_MODAL_STATES_KEYS, setModalValuesPropFunc, true)} checked={operationPerformedCorrecly} />} label="Yes" disabled={jobModalOnlyViewVisibility} />
                                    <FormControlLabel control={<Checkbox onChange={() => tooglePerformanceCorrectly(JOB_MODAL_STATES_KEYS, setModalValuesPropFunc, false)} checked={operationPerformedInCorrecly} />} label="No" disabled={jobModalOnlyViewVisibility} />
                                </FormGroup>
                            </Grid>

                            <Grid item xs={12}>
                                <Typography variant="p">Related scopes</Typography>
                                <FormGroup className="flex-row">

                                    {
                                        authsEndScopes.map(scopeCode => {
                                            const { scopes_form_label, scopes_form_onChangeFunc, scopes_form_checked, scopes_form_disabled } = scopesFormGroupControl(scopeCode, scopesSelected, setJobModalState)
                                            return <FormControlLabel key={scopeCode} disabled={scopes_form_disabled || jobModalOnlyViewVisibility} control={<Checkbox onChange={scopes_form_onChangeFunc} checked={scopes_form_checked} />} label={scopes_form_label} />
                                        })
                                    }

                                </FormGroup>
                            </Grid>

                            <Grid item xs={12}>
                                <DateYearFilterYMD componentContextDate={jobDateSelected} setFatherDate={(value) => setModalValuesPropFunc(JOB_MODAL_STATES_KEYS.jobDateSelected, value)} label={'Job date'} disabled={jobModalOnlyViewVisibility} />
                            </Grid>

                            <Grid item xs={12}>
                                <ModalTexField label={'Deviations'} value={deviations} rows={2} onChangeFunc={(value) => setModalValuesPropFunc(JOB_MODAL_STATES_KEYS.deviations, value)} disabled={jobModalOnlyViewVisibility} />
                            </Grid>

                            <Grid item xs={12}>
                                <ModalTexField label={'Coments'} value={comments} rows={3} onChangeFunc={(value) => setModalValuesPropFunc(JOB_MODAL_STATES_KEYS.comments, value)} disabled={jobModalOnlyViewVisibility} />
                            </Grid>

                        </Grid>


                    </Grid>



                    <Grid item xs={24}>
                        {
                            !jobModalOnlyViewVisibility &&
                            <Typography variant='p' className='italic'>
                                During the mentoring phase, the mentor should supervise inspections representative of the tasks that the inspector candidate will perform once the authorization is granted, and should supervise as many inspections as deemed necessary to ensure the inspector's autonomy in the scopes to be authorized.
                            </Typography>

                        }

                    </Grid>


                </Grid>


            </DialogContent>

            <DialogActions>
                <Button onClick={closeModal}>
                    Close
                </Button>
                {
                    !jobModalOnlyViewVisibility && (
                        <Button onClick={() => postInspectorJob(jobModalState)}>
                            Save
                        </Button>
                    )
                }

            </DialogActions>
        </Dialog>
    )

}
