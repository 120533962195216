import { REQUIREMENTS_COLUMS_NAMES_OBJ, TEAM_TRAINING_COLUMNS_NAMES_OBJ, REQUIREMENTS_AND_TEAM_TRAINING_INITIAL_STATE,EXPERIENCE_INITIAL_STATE } from "./consts"
import { checkIcon, wrongIcon } from "./icons"
import { MoodleTrainingTags } from '../Components/Tags.jsx'
import {  CourseLinearProgress } from '../Components/CourseLinearProgress.jsx';


export const REQUIREMENTS_AND_TEAM_TRAINING_ERROR_CONTROL=(skillMatrixObjState)=>({
    errorSkillMatrix: {
        ...REQUIREMENTS_AND_TEAM_TRAINING_INITIAL_STATE,
        loading:false,
        errorSkillMatrix: true,
        errorTeamTraining: true
    },
    noContentSkillMatrix: {
        ...REQUIREMENTS_AND_TEAM_TRAINING_INITIAL_STATE,
         noContentSkillMatrix: true,
         noContentTeamTrainig:true,
         loading: false,
     },
     noContentTeamTrainig: {
         ...REQUIREMENTS_AND_TEAM_TRAINING_INITIAL_STATE,
         requirements: skillMatrixObjState?.requirements,
        noContentSkillMatrix: false,
        noContentTeamTrainig:true,
        loading: false,
     },
    teamTrainingError: {
        ...REQUIREMENTS_AND_TEAM_TRAINING_INITIAL_STATE,
        requirements: skillMatrixObjState?.requirements,
        loading:false,
        errorSkillMatrix: false,
        errorTeamTraining: true
    }
})






export const SKILL_MATRIX_COLUMNS_FUNC=(tableColumnsNames, namesObj, widthValues)=>{

    const tableHeadersNames = Object.values(namesObj)
    const htmColumns = ["Evidenced", "Training", "No registered courses names", "Progress"]

    return tableColumnsNames.map(tableColumnName =>{

        if(htmColumns.includes(tableColumnName) ||!tableHeadersNames.includes(tableColumnName)){
            return { Header: tableColumnName, accessor: tableColumnName, sortable: false, type: 'html', width: widthValues?.[tableColumnName]}
        }

        else return { Header: tableColumnName, accessor: tableColumnName, sortable: true, type: 'string', width: widthValues?.[tableColumnName]}
    })

}


export const normalizedRequirementsDataFunc =({dataArr, columnsNames, vtgsColumns })=>{
    const moodleTrainingCodes = new Set()
    let normalizedRequirementsData = []

    const transformBoolToIcon = bool => bool ? checkIcon : wrongIcon


    dataArr.forEach(obj => {
        const newObj = {}
        for (let columnName of columnsNames) {
            if (!vtgsColumns.includes(columnName)) {

                const newColumnName = REQUIREMENTS_COLUMS_NAMES_OBJ[columnName]
                if (columnName === "Evidence") {
                    const isTrueOrfalse = obj[columnName] === "Evidencable" ? true : false
                    newObj[newColumnName] = transformBoolToIcon(isTrueOrfalse)
                }
                else if(columnName === "experience_qa"){
                    newObj[newColumnName] = obj[columnName] ?? " "

                }
                else if (columnName === "moodle_training_arr") {
                    const moodleCoursesArr = obj[columnName].replace("{", "").replace("}", "").split(",")
                    const tagValues = <MoodleTrainingTags tagsList={moodleCoursesArr} />

                    moodleCoursesArr.forEach(code => {if(!moodleTrainingCodes.has(code) && code.trim().length > 2) moodleTrainingCodes.add(code) })
                    newObj[newColumnName] = tagValues
                }
                else {
                    newObj[newColumnName] = obj[columnName]
                }
            }
            else {
                const columnNameNotDot = columnName.replace(".", "")
                newObj[columnNameNotDot] = transformBoolToIcon(obj[columnName])
            }
        }
        normalizedRequirementsData = [...normalizedRequirementsData, newObj]

    })
    return {normalizedRequirementsData, moodleTrainingCodes}
}

export const normalizedTeamTrainingFunc = ({teamTrainingData, teamTrainingColumnsNames, moodleTrainingCodes}) => {
    let normalizedTeamTraining = []
    const COURSES_REGISTERED_STATE = {
        registered: "MATRICULADO",
        noRegistered: "NO MATRICULADO",
    }
    const employeeKeys = ["username", "firstname", "lastname"]
    teamTrainingData.forEach(data => {
        let [doneCoursesCount, registeredCoursesCount, noRegisteredCoursesCount]  = [0,0,0]
        let noRegisteredCoursesNames = []

        let normalizedObj = {}
        const completeName = `${data.lastname}, ${data.firstname}`

        for(let columnName of teamTrainingColumnsNames){

            const moodleTrainingCodesHasColumnName = moodleTrainingCodes.has(columnName)

            const newColumnName = moodleTrainingCodesHasColumnName ? columnName.replace(".", "") : TEAM_TRAINING_COLUMNS_NAMES_OBJ[columnName]

            if(moodleTrainingCodesHasColumnName) {
                const courseInfo = data[columnName]
                if(COURSES_REGISTERED_STATE.registered === courseInfo) registeredCoursesCount+=1
                else if(COURSES_REGISTERED_STATE.noRegistered === courseInfo) {
                    noRegisteredCoursesCount+=1
                    // Ç Orden de cursos alfabeticamente y por codigo numerico
                    noRegisteredCoursesNames = [...noRegisteredCoursesNames, columnName].sort((a, b) => {

                        const [courseLettersA, courseNumbersA] = a.split(".");
                        const [courseLettersB, courseNumbersB] = b?.split(".");

                        // Comparar las letras
                        const compareLetters = courseLettersA.localeCompare(courseLettersB);

                        // Si las letras son diferentes, devolver el resultado de la comparación de letras
                        if (compareLetters !== 0) return compareLetters;

                        const compareNumbers = Number(courseNumbersA) - Number(courseNumbersB);
                        return compareNumbers;
                    });
                }
                else doneCoursesCount +=1
            }
            else if(employeeKeys.includes(columnName)) continue
            else normalizedObj[newColumnName] =  data[columnName]
        }

        let progress;
        if(doneCoursesCount !== 0 && registeredCoursesCount !== 0)  progress= (doneCoursesCount / (registeredCoursesCount + doneCoursesCount)) * 100
        else if(doneCoursesCount !== 0 && registeredCoursesCount === 0)  progress = 100
        else if(doneCoursesCount === 0 && registeredCoursesCount !== 0 || doneCoursesCount + registeredCoursesCount === 0)  progress = 0

        const tagValues = <MoodleTrainingTags tagsList={noRegisteredCoursesNames} />
        const couseProgessComp = <CourseLinearProgress value={progress} />


        normalizedObj = {
            ...normalizedObj,
            ["Employee"]: completeName,
            ["Registered"]: `${registeredCoursesCount}` ,
            ["No registered"]: `${noRegisteredCoursesCount}`,
            ["Done"]: `${doneCoursesCount}`,
            ["Progress"]:couseProgessComp,
            ["No registered courses names"]: tagValues,
        }

        normalizedTeamTraining = [...normalizedTeamTraining, normalizedObj]

    })
    return {normalizedTeamTraining}

}
