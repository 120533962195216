import { useState, useMemo, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import FormControl from '@mui/material/FormControl';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import FusePageCarded from '@fuse/core/FusePageCarded';
import Tabs from '@mui/material/Tabs';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Grid from '@mui/material/Grid'
import { SKILL_MATRIX_TAB_LABELS, SKILL_MATRIX_TAB_VALUES } from './utils/consts'
import { LinearLoader } from 'components/GlobalComponents/loaders/loader';
import { SkillMatrixProvider } from './utils/providerSkillMatrix';
import { useGetPermissions } from 'app/js/customHooks/PermissionsHooks';
import { getAllMissionsAction } from 'components/Managment/Contract/Missions/store/actions';
import { AutoCompleteByName } from 'components/GlobalComponents/ModalsFilters/AutocompleteFields';
import Requirements from './Requirements/Requirements.jsx'
import TeamTraining from './TeamTraining/TeamTraining.jsx'
import Experience from './Experience/Experience.jsx';

export const SkillMatrix = () => {
    const dispatch = useDispatch()
    const [tabValue, setTabValue] = useState(SKILL_MATRIX_TAB_VALUES.requirements)
    const [missionSelected, setMissionSelected] = useState(null);

    const { departamentalAndNoDepartamentalMissionsInfo } = useSelector(state => state.fuse.userComponente)
    const { allMissionsList } = useSelector(state => state.fuse.missionsReducer)
    const { canSeeAllPermission } = useGetPermissions()
    const canSeeAllSkillMatrix = useMemo(() => canSeeAllPermission('skill matrix'), [canSeeAllPermission])

    useEffect(() => {
        if (canSeeAllSkillMatrix && !allMissionsList.length) {
            dispatch(getAllMissionsAction())
        }
    }, [canSeeAllSkillMatrix, allMissionsList.length, dispatch]);

    const modalView = {
        [SKILL_MATRIX_TAB_VALUES.requirements]: <Requirements missionSelected={missionSelected} />,
        [SKILL_MATRIX_TAB_VALUES.teamTraining]: <TeamTraining missionSelected={missionSelected} />,
        [SKILL_MATRIX_TAB_VALUES.experience]: <Experience missionSelected={missionSelected} />,
    };

    return (
        <FusePageCarded
            content={
                <Box className="h-md w-full overflow-none">
                    <section className="w-full overflow-hidden" style={{ height: 'calc(100dvh - 17%)' }}>
                        <Grid container spacing={0}>
                            <Grid
                                item
                                xs={12}
                                md={8}>
                                <Tabs
                                    value={tabValue}
                                    onChange={(event, value) => setTabValue(value)}
                                    textColor="secondary"
                                    indicatorColor="secondary"
                                    aria-label="secondary tabs example"
                                >
                                    <Tab value={SKILL_MATRIX_TAB_VALUES.requirements} label={SKILL_MATRIX_TAB_LABELS.requirements} />
                                    <Tab value={SKILL_MATRIX_TAB_VALUES.teamTraining} label={SKILL_MATRIX_TAB_LABELS.teamTraining} />
                                    <Tab value={SKILL_MATRIX_TAB_VALUES.experience} label={SKILL_MATRIX_TAB_LABELS.experience} />
                                </Tabs>
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                md={4}
                                className={tabValue === '' ? 'hidden' : 'flex justify-end px-4 pt-3 gap-1'}
                                sx={{ marginTop: { xs: '10px', md: '0px' } }}
                            >
                                <FormControl variant="standard" fullWidth>
                                    <AutoCompleteByName
                                        options={canSeeAllSkillMatrix ? allMissionsList : departamentalAndNoDepartamentalMissionsInfo}
                                        value={missionSelected}
                                        setValueFunc={setMissionSelected}
                                        label={canSeeAllSkillMatrix && !allMissionsList.length ? 'Loading missions' : 'Missions'}
                                        disabled={canSeeAllSkillMatrix && !allMissionsList.length}
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                        <SkillMatrixProvider missionSelected={missionSelected}>
                            {modalView[tabValue]}
                        </SkillMatrixProvider>
                    </section>
                </Box>
            }
        />
    );
}
