import { useContext } from "react";
import { Table } from "components/GlobalComponents/tables/Table2"
import { LinearLoader } from 'components/GlobalComponents/loaders/loader';
import { skillMatrixContext } from "../utils/providerSkillMatrix";
import { CenteredDivInfo } from "components/GlobalComponents/CenteredDiv/TextCenteredDiv";

export default function TeamTraining({ missionSelected }) {
    const { loading, missionWithNoVTG, errorTeamTraining, noContentTeamTrainig, teamTraining } = useContext(skillMatrixContext);

    const renderContent = () => {
        if (!missionSelected) {
            return <CenteredDivInfo infoText={"Select a mission"} />
        }

        if (loading) {
            return <LinearLoader />
        }

        if (teamTraining.data) {
            return (
                <Table
                    rowsProp={teamTraining.data}
                    columnsProp={teamTraining.columns}
                    loading={false}
                    funcionSetValue={() => { }}
                />
            )
        }

        if (missionWithNoVTG && !errorTeamTraining) {
            return <CenteredDivInfo infoText={"Select a mission with visual time group associated"} />
        }

        if (errorTeamTraining) {
            return <CenteredDivInfo infoText={"Error, please, try again"} />
        }

        if (noContentTeamTrainig) {
            return <CenteredDivInfo infoText={"There is no team training data for the selected visual time group"} />
        }

        return null
    };

    return <>{renderContent()}</>
}
