import { useContext } from "react";
import { Table } from "components/GlobalComponents/tables/Table2"
import { LinearLoader } from 'components/GlobalComponents/loaders/loader';
import { skillMatrixContext } from "../utils/providerSkillMatrix";
import { CenteredDivInfo } from "components/GlobalComponents/CenteredDiv/TextCenteredDiv";
import { EXPERIENCE_COLUMNS } from "../utils/consts";

export default function Experience({ missionSelected }) {
    const {
        loadingExperience,
        missionWithNoAgressoCode,
        noContentExperience,
        errorExperience,
        experienceData
    } = useContext(skillMatrixContext);

    const renderContent = () => {
        if (!missionSelected) {
            return <CenteredDivInfo infoText={"Select a mission"} />
        }

        if (loadingExperience) {
            return <LinearLoader />
        }

        if (errorExperience) {
            return <CenteredDivInfo infoText={"Error, please, try again"} />
        }

        if (missionWithNoAgressoCode) {
            return <CenteredDivInfo infoText={"Select a mission with agresso code associated"} />
        }

        if (noContentExperience) {
            return <CenteredDivInfo infoText={"There is no experience for the selected agresso code"} />
        }

        if (experienceData) {
            return (
                <Table
                    rowsProp={experienceData}
                    columnsProp={EXPERIENCE_COLUMNS}
                    loading={false}
                    funcionSetValue={() => { }}
                />
            )
        }

        return null
    };

    return <>{renderContent()}</>
}
