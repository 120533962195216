import { createSlice } from "@reduxjs/toolkit"
import {
    closeInpectorAuthThunk,
    editInspectorThunk,
    getInspectorBasicInfoThunk,
    getInspectorsContinuousEvaluationThunk,
    getInspectorsThunk,
    postInspectorJobThunk,
    postInspectorThunk
} from "./AuthOfInspectorsThunk"
import {
    addStateByLastJobDateDateToInpectorList,
    getAllInspectorJobs,
    insertScopeNameInInspectorList,
    setInspectorsBasicList,
    setInspectorsDetailList
} from "../utils/functions"

const INITIAL_STATE = {
    status: {
        getMoreInspectorsData: 'idle',
    },
    error: {
        getMoreInspectorsData: false,
    },
    dataPaginationDetails: {
        count: 0,
        next: null,
        previous: null,
        current: null,
        total_pages: 0,
        number_of_printed_data: 0
    },
    inspectorBasicList: [],
    inspectorsList: [],
    inspectorDataDetailTable: [],
    loadingInspectorsList: true,
    errorInspectorsList: false,
    inspectorBasicListRowSelected: null,
    inspectorRowSelected: null,
    createInspectorModalVisibility: false,
    editInspectorModalVisibility: false,
    createJobModalVisibility: false,
    closeAuthModalVisibility: false,
    continuousEvaluationModalVisibility: false,
    detailsAuthTableVisibility: false,
    jobsTableVisibility: false,
    jobRowSelected: null,
    inspectorJobsInJobsTable: [],
    jobModalOnlyViewVisibility: false,
    userCanBeInspector: null,
    userBasicCoursesInfo: null,
    userScope: null,
    belowPeopleInStructure: [],
    inpectorSelectedJobsInfo: { totalJobs: 0, correctPerformanceJobsNumber: 0 },
    usersStructureUpperN0: []
}

const authorizationOfInspectorsSlice = createSlice({
    name: 'authorizationOfInspectors',
    initialState: INITIAL_STATE,
    reducers: {
        setCreateInspectorModalVisibility: (state, { payload }) => {
            state.createInspectorModalVisibility = payload
        },
        setEditInspectorModalVisibility: (state, { payload }) => {
            state.editInspectorModalVisibility = payload
        },
        setJobModalVisibility: (state, { payload }) => {
            state.createJobModalVisibility = payload
        },
        setCloseAuthModalVisibility: (state, { payload }) => {
            state.closeAuthModalVisibility = payload
        },
        setContinuousEvaluationModalVisibility: (state, { payload }) => {
            state.continuousEvaluationModalVisibility = payload
        },
        setDetailAuthTableVisibilityAction: (state, { payload }) => {
            state.detailsAuthTableVisibility = payload
            const { inspectorDataDetailTable } = state
            const jobsObj = payload ? getAllInspectorJobs(inspectorDataDetailTable) : []
            state.inspectorJobsInJobsTable = jobsObj
        },
        setJobsTableVisibility: (state, { payload }) => {
            state.jobsTableVisibility = payload
            state.jobRowSelected = payload ? state.jobRowSelected : null
            state.detailsAuthTableVisibility = !payload
            state.inspectorRowSelected = !payload ? null : state.inspectorRowSelected
        },
        setBelowPeopleInStructure: (state, { payload }) => {
            state.belowPeopleInStructure = payload
        },
        setInspectorRowSelectedAction: (state, { payload }) => {
            if (!payload) {
                state.inspectorRowSelected = null
            } else {
                const { jobs } = payload
                const correctPerformanceJobsNumber = jobs.filter(({ peration_performed_correctly, active }) => peration_performed_correctly && active).length
                state.inspectorRowSelected = payload
                state.inpectorSelectedJobsInfo = { totalJobs: jobs.length, correctPerformanceJobsNumber }
            }
        },
        setInspectorBasicListRowSelectedAction: (state, { payload }) => {
            const inspectorAuthDetails = payload && setInspectorsDetailList(state.inspectorsList, payload)
            state.inspectorDataDetailTable = inspectorAuthDetails || state.inspectorDataDetailTable
            state.inspectorBasicListRowSelected = payload
        },
        resetUserCanBeInspector: (state) => {
            state.userCanBeInspector = null
        },
        setUsersStructureUpperN0: (state, { payload }) => {
            state.usersStructureUpperN0 = payload
        },
        setJobRowSelected: (state, { payload }) => {
            state.jobRowSelected = payload
        },
        setJobModalOnlyViewVisibility: (state, { payload }) => {
            state.jobModalOnlyViewVisibility = payload
        }
    },
    extraReducers: (builder) => {
        builder
            // Fetch Inspectors
            .addCase(getInspectorsThunk.pending, (state) => {
                state.inspectorsList = []
                state.loadingInspectorsList = true
                state.errorInspectorsList = false
            })
            .addCase(getInspectorsThunk.fulfilled, (state, { payload }) => {
                const inspectorListWithScopeName = insertScopeNameInInspectorList(payload)
                state.inspectorsList = inspectorListWithScopeName
                state.inspectorBasicList = setInspectorsBasicList(inspectorListWithScopeName)
                state.loadingInspectorsList = false
                state.errorInspectorsList = false
            })
            .addCase(getInspectorsThunk.rejected, (state) => {
                state.inspectorsList = []
                state.loadingInspectorsList = false
                state.errorInspectorsList = true
            })
            // Post Inspector
            .addCase(postInspectorThunk.fulfilled, (state, { payload }) => {
                const inspectorListWithScopeName = insertScopeNameInInspectorList([...state.inspectorsList, ...payload])
                state.inspectorsList = inspectorListWithScopeName
                state.inspectorBasicList = setInspectorsBasicList(inspectorListWithScopeName)
                state.userCanBeInspector = null
                state.userScope = null
                state.userBasicCoursesInfo = null
            })
            // Edit Inspector
            .addCase(editInspectorThunk.fulfilled, (state, { payload }) => {
                let updatedList = state.inspectorsList
                for (let inspectorAuth of payload) {
                    const indexToUpdate = updatedList.findIndex(({ id }) => id === inspectorAuth.id)
                    updatedList[indexToUpdate] = inspectorAuth
                }
                updatedList = insertScopeNameInInspectorList(updatedList)
                state.inspectorsList = updatedList
                const inspectorDetailsUpdate = setInspectorsDetailList(updatedList, state.inspectorBasicListRowSelected)
                state.inspectorBasicList = setInspectorsBasicList(updatedList)
                state.inspectorDataDetailTable = inspectorDetailsUpdate
            })
            // Post Inspector Job
            .addCase(postInspectorJobThunk.fulfilled, (state, { payload }) => {
                const { inspetor_auths } = payload
                inspetor_auths.forEach(authModify => {
                    const authIndex = state.inspectorsList.findIndex(({ id }) => id === authModify.id)
                    const authUpdate = { ...state.inspectorsList[authIndex], ...authModify }
                    if (authModify.id === state.inspectorRowSelected?.id) {
                        state.inspectorRowSelected = authUpdate
                    }
                    state.inspectorsList[authIndex] = authUpdate
                })
                const inspectorListWithScopeName = insertScopeNameInInspectorList(state.inspectorsList)
                state.inspectorsList = inspectorListWithScopeName
                const inspectorDetailsUpdate = setInspectorsDetailList(inspectorListWithScopeName, state.inspectorBasicListRowSelected)
                state.inspectorBasicList = setInspectorsBasicList(inspectorListWithScopeName)
                state.inspectorDataDetailTable = inspectorDetailsUpdate
            })
            // Close Inspector Auth
            .addCase(closeInpectorAuthThunk.fulfilled, (state, { payload }) => {
                const inspectorIndex = state.inspectorsList.findIndex(({ id }) => id === payload.id)
                if (inspectorIndex > -1) {
                    state.inspectorsList[inspectorIndex] = { ...state.inspectorsList[inspectorIndex], ...payload }
                }
                state.inspectorDataDetailTable = setInspectorsDetailList(state.inspectorsList, state.inspectorBasicListRowSelected)
            })
            // Continuous Evaluation
            .addCase(getInspectorsContinuousEvaluationThunk.fulfilled, (state, { payload }) => {
                const { inspectors_ids, programs_ids, programs_info } = payload
                state.continuousEvInfo = { inspectorsIds: inspectors_ids, programsIds: programs_ids, programsInfo: programs_info }
            })
            .addCase(getInspectorsContinuousEvaluationThunk.rejected, (state) => {
                state.continuousEvInfo = INITIAL_STATE.continuousEvInfo
            })
    }
})

export const {
    setCreateInspectorModalVisibility,
    setEditInspectorModalVisibility,
    setBelowPeopleInStructure,
    setJobsTableVisibility,
    setInspectorRowSelectedAction,
    setJobModalVisibility,
    setCloseAuthModalVisibility,
    setContinuousEvaluationModalVisibility,
    setInpectorSelectedJobsInfo,
    setInspectorBasicListRowSelectedAction,
    setDetailAuthTableVisibilityAction,
    resetUserCanBeInspector,
    setUsersStructureUpperN0,
    setJobRowSelected,
    setJobModalOnlyViewVisibility
} = authorizationOfInspectorsSlice.actions

export const dataPaginationDetails = state => state.fuse.inspectorsComponent.dataPaginationDetails

export default authorizationOfInspectorsSlice.reducer
