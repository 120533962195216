import {

    MOSTRAR_PROCESS,
    MOSTRAR_PROCESS_EXITO,
    MOSTRAR_PROCESS_ERROR,

    CAMBIAR_VALOR_SELECCION_GRID_PROCESS,
    CAMBIAR_VALOR_SELECCION_GRID_PROCESS_EXITO,
    CAMBIAR_VALOR_SELECCION_GRID_PROCESS_ERROR,

    PUT_PROCESS,
    PUT_PROCESS_EXITO,
    PUT_PROCESS_ERROR,

    INSERTAR_NEW_PROCESS,
    INSERTAR_NEW_PROCESS_EXITO,
    INSERTAR_NEW_PROCESS_ERROR,

    CAMBIAR_MODAL_NEW_PROCESS,
    CAMBIAR_MODAL_NEW_PROCESS_EXITO,
    CAMBIAR_MODAL_NEW_PROCESS_ERROR


} from './types';


// cada reducer tiene su propio state
const initialState = {
        processListAPI:[],
        filaSeleccionadaGrid: '',
        error: null,
        loading: false,
        visibilidadNewProcess: false,
        modoDialogProcess: '',
}


export default function(state = initialState, action) {

    switch(action.type) {

        //**********VISIBILIDAD MODAL INSERTAR**********************************/
    case CAMBIAR_MODAL_NEW_PROCESS:
        return {
            ...state,
            loading: action.payload
        }

        case CAMBIAR_MODAL_NEW_PROCESS_EXITO:
            return {
                ...state,
                loading: false,
                visibilidadNewProcess: action.payload,
                modoDialogProcess: action.nombre
            }

        case CAMBIAR_MODAL_NEW_PROCESS_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload

            }

        //post user

        case INSERTAR_NEW_PROCESS:
            return {
                ...state,
                loading: action.payload
            }

        case INSERTAR_NEW_PROCESS_EXITO:
            return {
                ...state,
                loading: false,
                error: null
            }

        case INSERTAR_NEW_PROCESS_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload

            }

        //UPDATE PROCESS
    case PUT_PROCESS:
        return {
            ...state,
            loading: action.payload
        }

    case PUT_PROCESS_EXITO:
        return {
            ...state,
            loading: false,
            error: null

        }

    case PUT_PROCESS_ERROR:
        return {
            ...state,
            loading: false,
            error: action.payload

        }


        //********** MOSTRAR PROCESS API**********************************/

    case MOSTRAR_PROCESS:
        return {
            ...state,
            loading: action.payload
        }

    case MOSTRAR_PROCESS_EXITO:
        return {
            ...state,
            loading: false,
            error: null,
            processListAPI: action.payload
        }

    case MOSTRAR_PROCESS_ERROR:
        return {
            ...state,
            loading: false,
            error: action.payload

        }
    //**********FILA SELECIONADA GRID **********************************/

    case CAMBIAR_VALOR_SELECCION_GRID_PROCESS:
        return {
            ...state,
            loading: action.payload
        }

    case CAMBIAR_VALOR_SELECCION_GRID_PROCESS_EXITO:
        return {
            ...state,
            loading: false,
            filaSeleccionadaGrid: action.payload

        }

    case CAMBIAR_VALOR_SELECCION_GRID_PROCESS_ERROR:
        return {
            ...state,
            loading: false,
            error: action.payload

        }

        default:
            return state

    }

}
