import { useState } from 'react'
import { ChipComponent } from 'components/GlobalComponents/ChipComponent/ChipComponent'
import { Tooltip, IconButton } from '@mui/material'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import CancelIcon from '@mui/icons-material/Cancel'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'

export const TableRows = ({ row, cellMaxWidth }) => {
    const [copied, setCopied] = useState(false)

    const COLUMN_TYPE_RESPONSE = {
        html: (cell, column, renderIndex) => (
            <td {...cell.getCellProps()} className="text-center  w-px max-w-[70px] h-[60px] overflow-auto">
                {cell.render("Cell")}
            </td>
        ),
        check: (cell, column, renderIndex) => {
            if (!!cell.value) {
                return (
                    <Tooltip title={column?.textTrue} placement="top" key={renderIndex}>
                        <td {...cell.getCellProps()} className="text-center  w-px max-w-[70px] h-[60px] overflow-auto">
                            <CheckCircleIcon color="primary" />
                        </td>
                    </Tooltip>
                )
            }
            else {
                return (
                    <Tooltip title={column?.textFalse} placement="top" key={renderIndex}>
                        <td {...cell.getCellProps()} className="text-center  w-px max-w-[70px] h-[60px] overflow-auto">
                            <CancelIcon color="success" />
                        </td>
                    </Tooltip>
                )
            }
        },
        porcentaje: (cell, column, renderIndex) => {
            if (isNaN(cell.value)) {
                return (
                    <Tooltip title={cell.render("Cell")} key={renderIndex}>
                        <td {...cell.getCellProps()} className="text-center  w-px max-w-[70px] h-[60px] overflow-auto">
                            {cell.value?.toString()?.length > 100 ? cell.value?.toString()?.substring(0, 100) + "..." : cell.render("Cell")}
                        </td>
                    </Tooltip>
                )
            }
        }
    }

    const DEFAULT_COLUMN_TYPE_RESPONSE = (cell, renderIndex, column, tooltip, isExpanded) => {
        const { value } = cell
        if ((value !== null && value !== undefined && value !== '') || column.type === 'expand') {
            const shouldAllowCopy = !isExpanded && typeof cell.value === 'string' && cell.value?.length > 50

            const handleCopy = () => {
                if (shouldAllowCopy) {
                    navigator.clipboard.writeText(cell.value)
                    setCopied(true)
                    setTimeout(() => setCopied(false), 2000)
                }
            }

            return (
                <Tooltip
                    title={
                        column.type !== 'expand'
                            ? copied
                                ? "Copied!"
                                : cell.render("Cell")
                            : ''
                    }
                    key={renderIndex}
                    disableHoverListener={column.type === 'expand'}
                >
                    <td
                        {...cell.getCellProps()}
                        className="w-px max-w-[70px] h-[60px] overflow-auto"
                    >
                        <div
                            className={`h-full flex
                            ${(isExpanded && cell.row.allCells.some(c => c.value?.toString()?.length > 50))
                                    ? 'items-start justify-center pt-4'
                                    : 'items-center justify-center'
                                }`}
                        >
                            <div
                                className={`text-center overflow-hidden m-0 ${shouldAllowCopy ? 'cursor-pointer' : ''}`}
                            >
                                {isExpanded
                                    ? (typeof cell.value === 'string' && cell.value?.length > 130
                                        ? <div className="text-justify">{cell.render("Cell")}</div>
                                        : cell.render("Cell"))
                                    : (typeof cell.value === 'string' && cell.value?.length > 50
                                        ? <>
                                            {cell.value.substring(0, 50)}...
                                            <IconButton onClick={handleCopy} className="float-right">
                                                <ContentCopyIcon className="text-[#272727] text-xs" />
                                            </IconButton>
                                        </>
                                        : cell.render("Cell"))
                                }
                            </div>
                        </div>
                    </td>
                </Tooltip>
            )
        }

        return (
            <Tooltip title={cell.render("Cell")} key={renderIndex}>
                <td {...cell.getCellProps()} className="w-px max-w-[70px] h-[60px] overflow-auto">
                    <div className="h-full flex items-center justify-center">
                        -
                    </div>
                </td>
            </Tooltip>
        )
    }

    const CHIPPED_COLUMN_TYPE_RESPONSE = (cell, renderIndex, chip, column, tooltip, isExpanded) => {
        const { value } = cell
        if (value !== null && value !== undefined && value !== '') {
            return (
                <td {...cell.getCellProps()} className=" w-px max-w-[70px] h-[60px] overflow-auto">
                    <ChipComponent cell={cell} chip={chip} />
                </td>
            )
        }
        return (
            <Tooltip title={cell.render("Cell")} key={renderIndex}>
                <td {...cell.getCellProps()} className=" w-px max-w-[70px] h-[60px] overflow-auto">
                    <div className="h-full flex items-center justify-center">
                        -
                    </div>
                </td>
            </Tooltip>
        )
    }

    return (
        <>
            {row.cells.map((cell, renderIndex) => {
                const { column } = cell
                const { type, chip = false } = column
                const tooltip = column.tooltip === undefined ? true : column.tooltip
                const isExpanded = row.isExpanded

                return COLUMN_TYPE_RESPONSE[type]
                    ? COLUMN_TYPE_RESPONSE[type](cell, column, renderIndex)
                    : chip
                        ? CHIPPED_COLUMN_TYPE_RESPONSE(cell, renderIndex, chip, column, tooltip, isExpanded)
                        : DEFAULT_COLUMN_TYPE_RESPONSE(cell, renderIndex, column, tooltip, isExpanded)
            })}
        </>
    )
}
